.input-box {
	flex-grow: 1;
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border-bottom: 1px solid #d2d6de;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	/*margin: 8px 0 0 0;*/
	/*****new*****/
	font-weight: 600;
	
}
.input-box:focus {
	color: #495057;
	background-color: #fff;
	/*border-color: #80bdff;*/
	outline: 0;
	/*box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);*/
}
.input-box:focus + .search-result, .input-box:focus .search-result {
	display: block;
}
.search-result:hover {
	display: block;
}
.search-result {
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	margin-top: 0;
	list-style: none;
	padding-inline-start: 0;
	display: none;
	max-height: 11rem;
	overflow: scroll;
	/*****new*****/
	position:absolute;
    top: 100%;
	width:100%;
	z-index:2;
	background-color:#ffffff;
	border-top: 1px solid #ccc;
}
.search-result > .list-result {
	padding: 10px;
	/*****new*****/
	background-color:#ffffff;
}
/* checkbox css starts here */
/* The container */
.search-result > .list-result .container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 15px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-weight:normal;
	/*****new*****/
	width:auto !important;
	height:auto !important;
}

/* Hide the browser's default checkbox */
.search-result > .list-result .container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #eee;
	/*border-radius: .25rem;*/
	/*****new*****/
	border-radius:0;
	/*****new*****/
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/* On mouse-over, add a grey background color */
.search-result > .list-result .container:hover input ~ .checkmark {
	background-color: #ccc;
	box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, .25);
}

/* When the checkbox is checked, add a blue background */
.search-result > .list-result .container input:checked ~ .checkmark {
	background-color: #333333;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.search-result > .list-result .container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.search-result > .list-result .container .checkmark:after {
	/*left: 6px;
	top: 2px;*/
	/*****new*****/
	left:7px;
	top:4px;
	/*****new*****/
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.multiSelect {
	text-align: left;
	/*margin: 12px;*/
	display: flex;
    flex-direction: column;
	/*****new*****/
	position:relative;
	width:220px;
}
/* chip css */
.chip {
	/*flex-grow: 1;*/
	flex-grow: 0;
	/*****new*****/
	position:absolute;
	width:100%;
	background-color:#ffffff;
	top:10px;
	
}
.chip-body {
	min-width: 55px;
	width: auto;
	/*background-color: #333333;
	color: #ffffff;*/
	
	background-color: #ffffff;
	color: #333333;
	/*padding: 5px;*/
	padding: 3px;
	text-align: center;
	border-radius: 0rem;
	display: inline-block;
	margin-left: 3px;
	margin-bottom: 3px;
	
	
}
.chip-text {
	margin: 0;
	display: inline;
	font-size:1.2rem;
	/*****new*****/
	position: relative;
    /*top: -2px;*/
}
.chip-close {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	float: right;
	cursor: pointer;
	margin-left: 3px;
}

/********new*********/
.chip::after {
	
	display: inline-block;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid;
	border-right: .3em solid transparent;
	border-bottom: 0;
	border-left: .3em solid transparent;

	position: absolute;
    right: 12px;
    top: 8px;
	color: #939ba2;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px;
    border: none;
}
  
  /* Snow Theme */
  .ql-snow.ql-toolbar {
	display: block;
	background: #eaecec;
	border-top-left-radius: 0.5em;
	border-top-right-radius: 0.5em;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
  }
