.intl-tel-input {
    position: relative;
    display: inline-block;
    width: 100%;
    }
.intl-tel-input .flag-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        padding: 1px;
        }