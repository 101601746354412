.select2 {
  width: 100% !important;
}

#fdupld > input {
  display: none;
}
input[type='file'] {
  display: none;
}
.iti-flag {
  display: block;
  /* background: none; */
  margin-top: 0;
  top: 0;
  position: relative;
}
.intl-tel-input .flag-container .arrow {
  top: 33%;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: none;
}
.ck.ck-toolbar {
  background: #defbf2 !important;
  border-radius: 8px !important;
  border: none !important;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border: none !important;
}

.ck.ck-balloon-panel {
  z-index: 1100 !important ;
}
